import { Container } from '@mui/material'
import React, { Component } from 'react'
import Header from '../common/header'
import BookNow from '../common/book'
import Links from '../common/links'

class Home extends Component {
    render() {
        return (
            <div>
                <div style={{ maxHeight: '100vH', overflow: 'hidden', scrollSnapAlign: 'start' }}> 
                <Header>
               </Header>
                <Container style={{ display: 'flex', height: '90VH', justifyContent:'center' }} >
                    <div className="column-home">
                        <div style={{gap:'10vh' }}>
                            <h1 style={{zIndex:2, position:'relative'}} className="main">Prilika za promenu navika</h1>
                            <h2 style={{zIndex:2, position:'relative', color:'white', fontSize:"25px", fontWeight:'normal' }} className= "hide-element-on-small" >Ne traži savršeni plan. Stvori svoj</h2>
                            </div> 
                            <div style={{ position:'relative'}}>
                                <BookNow/>
                            </div>
                        </div>
                        <div className='links-wrapper' ><Links></Links></div>    
                    </Container>
                </div>
            </div>
        )
    }
}

export default Home