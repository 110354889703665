import React from 'react'
import { SquareImage, Conclusion, RectangleImage, SquareImageLeft } from '../blog_visuals.js';

import FirstImage from '../blog_images/gladovanje_i_post/img1.jpg'
import SecondImage from '../blog_images/gladovanje_i_post/img2.jpg'
import ThirdImage from '../blog_images/gladovanje_i_post/img3.jpg'
import FourthImage from '../blog_images/gladovanje_i_post/img4.jpg'
import FifthImage from '../blog_images/gladovanje_i_post/img5.jpg'
import SixthImage from '../blog_images/gladovanje_i_post/img6.jpg'
import SeventhImage from '../blog_images/gladovanje_i_post/img7.jpg'
import EightImage from '../blog_images/gladovanje_i_post/img8.jpg'
import NinethImage from '../blog_images/gladovanje_i_post/img9.jpg'
import TenthImage from '../blog_images/gladovanje_i_post/img10.jpg'



const BlogPost14 = () => {
    return (
    <div style={{width:'100VW', overflowWrap: 'anywhere', paddingTop:'15px'}}>
            <p>Današnji tekst neće se baviti popularnim dijetama poput 8:16 ili autofagije, već gladovanjem i postom kroz prizmu evolucije, kulture i nauke. O periodičnom gladovanju i sličnim režimima ishrane koji uključuju vremensko ograničenje unosa hrane sam već pisao, pa ako želite da pročitate više o njima idite ovde: <a href="/science/blog_post/2">Periodično gladovanje</a>.</p>
<p><em>Gladovanje</em> jednostavno znači da duži period ne unosimo hranu, post je voljno gladovanje, tako da su ova dva termina vrlo slična. Za potrebe ovog teksta koritiću i reč <em>post</em> i reč<em> gladovanje</em>, misleći na period tokom kojeg se hrana uopšte ne unosi.</p>
<RectangleImage src= {FirstImage} alt="Description"></RectangleImage>
<p>Činjenica da post postoji u gotovo svim religijama i svim delovima sveta ukazuje na njegov značaj. Ovo je izuzetan mehanizam i stečena sposobnost čoveka koja je ukorenjena u njegovoj dugoj istoriji i biologiji. U hrišćanstvu, post nije samo telesna, već i duhovna praksa; u islamu, post tokom Ramazana spaja zajednicu i jača volju; dok u hinduizmu i budizmu on simbolizuje pročišćenje i disciplinu. U svetu pre modernih religija, naši preci, lovci-sakupljači, često su se suočavali sa periodima oskudice čime su bili na prinudnom postu, a što je oblikovalo metabolizam kakav danas imamo.</p>
<p>Danas, u vremenu obilja i konstantne dostupnost hrane, gladovanje deluje kao arhaičan i radikalan koncept. Sa ovim sam se puno puta susreo u ordinaciji, gde sam spomen toga da se ne jede 24 sata izaziva raširene oči i odmahivanje glavom uz reči „Nemoguće!” ili „Ceo dan?”. Istraživanja i lična iskustva govore da je post ne samo izuzetno dobar za telo, već i da ima veliku vrednost za duh čoveka.</p>
<p>Nameće se pitanje, zašto post opstaje kroz vekove. Odgovor leži u njegovim brojnim benefitima, od regulacije metabolizma i čišćenja organizma do mentalnog mira i boljeg fokusa. Ovaj tekst ima za cilj da vam pruži dublje razumevanje ove prastare prakse koja je upravo ono što nam je danas potrebno.</p>

<h2>Evolucija posta</h2>
<p>Od prvih tragova ljudske vrste, pa do modernog doba, navike u ishrani oblikovane su dostupnošću hrane u okruženju. Hrana nikada nije bila konstantno dostupna i obilna kao danas. Većina naših predaka oslanjala se na periode obilja kada bi uspeli da ulove ili prikupe hranu, ali su istovremeno prolazili kroz periode gladi, koji su bili neizbežan deo života. Dodatni problem sa glađu bila je njena nepredvidivost, dok je moguće pripremiti se za sezonske nestašice, brojni uzroci izazivali su iznenadne nestašice. Ova nepredvidivost dovela je do razvoja metaboličkih mehanizama za iznenadne periode gladi, što je čoveku omogućilo preživljavanje i adaptaciju u različitim ekosistemima širom sveta i uzrokovalo da danas ljudi žive svuda, od Severnog do Južnog pola.</p>
<SquareImage src={SecondImage} alt="Description">
<p><a rel="nofollow" href="https://pmc.ncbi.nlm.nih.gov/articles/PMC9460423/">Naše shvatanje</a> je da u prirodnom okruženju, životni ciklusi svih organizama, uključujući ljude, zavise od dostupnosti resursa. Prirodni ciklusi regulišu raspoloživost nutrijenata kroz složene ekološke interakcije. Primarna proizvodnja biljaka i sekundarna proizvodnja herbivora kontrolišu energetske tokove unutar ekosistema, dok je finalna razgradnja organskih materija ključna za obnavljanje hranljivih sastojaka i zemljišta, čime ciklus može ponovo da otpočne.</p>
<p>Ono što razlikuje ljudsku vrstu od drugih sisara jeste neverovatna sposobnost za prilagođavanje najrazličitijoj hrani, od pretežno biljne ishrane, kakvu imaju ljudi u tropskim regijama do ishrane bogate mastima i proteinima kod populacija poput Inuita. Ova metabolička fleksibilnost bila je ključna za preživljavanje, ali je takođe značila da je ljudsko telo razvilo efikasne mehanizme za održavanje energije tokom dužih perioda bez hrane.</p>

<p>Današnji ljudi, mi, druga smo ili treća generacija ljudi koja ima mogućnost svakodnevnih redovnih obroka, pa nam je glad strana. Međutim, gladovanje je kroz dugu istoriju čoveka (oko 200 hiljada godina) bilo toliko uobičajeno da ljudi koji nisu imali dobru adaptaciju na glad jednostavno nisu preneli svoje gene dalje. Iz tog razloga, svi ljudi koji danas žive potomci su onih koji su se uspešno prilagodili na periode gladovanja, pa svako od nas ima ovu neverovatnu sposobnost.</p>
</SquareImage>
<Conclusion>Naš organizam je evoluirao uz periode gladi i obilja, razvijajući metaboličku fleksibilnost kao ključnu prednost za preživljavanje. Iako danas glad gotovo da ne postoji, naše telo i dalje funkcioniše kao da se sprema za sledeću nestašicu.</Conclusion>
<h2>Metabolizam gladovanja</h2>
<p>Naša adaptiranost na periode posta može se dokazati <a rel="nofollow" href="https://www.cambridge.org/core/journals/proceedings-of-the-nutrition-society/article/metabolic-adaptations-during-negative-energy-balance-and-their-potential-impact-on-appetite-and-food-intake/92FC7CC3D00F801FBC1B8F4A164ADECD">postojanjem</a> brojnih promena u radu našeg tela i metabolizma pri relativno kratkim periodima gladovanja. Jedan od prvih odgovora organizma na smanjeni energetski unos je smanjenje energetske potrošnje i prelazak na sekundarne izvore energije. Međutim, ovaj proces je daleko komplikovaniji jer telo dalje aktivira niz hormonskih i metaboličkih mehanizama kako bi optimizovao korišćenje raspoloživih resursa kako bi što duže održavalo telo sposobnim za nalaženje hrane.</p>
<SquareImageLeft src={ThirdImage} alt="Description">
<p>Kada telo ne dobija hranu dolazi do niza metaboličkih promena kako bi očuvalo energiju i osiguralo funkcionisanje vitalnih organa. U početnim satima gladovanja, telo koristi glukozu iz jetrenih zaliha kao primarni izvor energije. Međutim, ove rezerve se brzo iscrpljuju, kraće od 24 sata, što telo primorava da pređe na alternativne izvore energije.</p>
<p>Tokom produženog gladovanja, ključnu ulogu preuzimaju dva procesa koja su u normalnim situacijama utišana, glukoneogeneza i lipoliza, odnosno unutrašnje stvaranje šećera i razgradnja rezervi masti. Glukoneogeneza omogućava proizvodnju glukoze iz ne-šećera, dok se lipolizom razgrađuju masne kiseline u jetri i proizvode ketonska tela. Ketonska tela predstavljaju alternativni izvor energije za mozak i druge organe koji ne mogu da koriste masne kiseline direktno. Ovaj metabolički preokret omogućava aktivaciju energije iz „skladišta” i preživljavanje u uslovima produženog posta.</p>
</SquareImageLeft>
<p>Osim što podstiče sagorevanje masti, post takođe aktivira metaboličke puteve povezane sa dugovečnošću. <a rel="nofollow" href="https://royalsocietypublishing.org/doi/10.1098/rsob.200176">Studije</a> na različitim organizmima, od glodara do primata, ukazuju da kalorijska restrikcija može značajno produžiti životni vek, prvenstveno putem promene signalnih puteva. Iako su ovi efekti kod ljudi i dalje predmet istraživanja, sve je više dokaza da povremeni post može doprineti metaboličkom zdravlju, a potencijalno i usporiti proces starenja.</p>
<p>Iako iz ovoga može izgledati da je gladovanje dobar način za kontrolu težine ili smanjenje masti u organizmu, jedan od ključnih fenomena u adaptacijama na gladovanje je adaptivna termogeneza, pri čemu dolazi do smanjenja potrošnje energije tela. Pri naglom smanjenju kilaže telo smanjuje količinu energije koja mu je neophodna za pravilno funkcionisanje (bazalni metabolizam), i na taj način se suprotstavlja daljem smanjenju kilaže. Adaptivna termogeneza je uzrok nastanka platoa pri naglim gubicima težine, kao i brzog vraćanja kilaže nakon rigoroznih dijeta. Ovo je jedan od razloga zašto sam veliki protivnik restriktivnih dijeta. Kada dođe do naglog smanjenja hrane i gubitka težine, dolazi i do usporavanja ili zaustavljanja mehanizama u našem telu kako bi se energija što manje trošila. Na ovaj način postiže se suprotan efekat od željenog: ljudi idu na dijetu kako bi smršali, a njihovo telo smanjuje potrošnju kako ne bi smršalo, pa samim tim upadaju u izgubljenu borbu protiv svoje biologije i svog tela.</p>
<Conclusion>Naše telo je evolutivno prilagođeno na gladovanje kroz efikasne mehanizme preživljavanja, ali upravo ti mehanizmi nas mogu sabotirati kada pokušavamo brzo da smršamo. Ekstremne dijete zato često ne daju trajne rezultate.</Conclusion>
<h2>Post u religiji</h2>

<p>Pored toga što je biološki i evolutivni fenomen, praksa posta duboko je ukorenjena u praktično svim modernim religijama i brojnim kulturama širom sveta. Razlozi za post su uvek povezani sa duhovnim pročišćenjem, pokajanjem i disciplinom, ali je interesantno primetiti da je prepoznata i vrednost posta kao načina za neki vid mentalnog i fizičkog reseta.</p>
<RectangleImage src= {FourthImage} alt="Description"></RectangleImage>
<h3>Hrišćanski post</h3>
<p>U hrišćanstvu post ima dugu tradiciju i prisutan je u različitim oblicima. U pravoslavlju i katoličanstvu post je strogo definisan crkvenim pravilima, a u protestantskim tradicijama post je značajna, ali individualna odluka. Post se ne posmatra samo kao fizička praksa, već i kao način duhovnog preobražaja i pokajanja. Isusov četrdesetodnevni post u pustinji, koji se često uzima kao primer, naglašava post kao čin iskušenja, ali i duhovne snage i izdržljivosti. Po tekstu <a    rel="nofollow" href="http://arhiva.spc.rs/sr/hrishtshanski_post_1.html">Srpske pravoslavne crkve</a> post nije samo telesno odricanje, već i duhovna disciplina koja pomaže vernicima da se približe Bogu, oslobode greha i razviju vrline poput smirenosti, strpljenja i ljubavi.</p>
<SquareImage src={FifthImage} alt="Description">
<p>Sa aspekta ishrane, pravoslavni post uključuje uzdržavanje od mesa, mlečnih proizvoda i jaja, čime se povećava unos žitarica, voća, pasulja, povrća i ribe. Sa višednevnim postovima i jednodnevnim postom (sredom i petkom) kojeg se pridržava veliki broj vernika, pravoslavni post traje između 180 i 200 dana godišnje. Ovakav način ishrane možemo nazvati periodična vegetarijanska ishrana, koja, zbog prisustva ribe i morskih plodova, kao i malog unosa mesa, veoma liči na mediteransku ishranu. O benefitima mediteranske ishrane možete pročitati na <a href="/science/blog_post/4">OBJAVI</a> posvećenoj samo njoj, ali je zaključak da je mediteranska ishrana ne samo moj omiljeni način ishrane, već i jedan od najzdravijih po mišljenju mnogih studija i stučnjaka, pa je samim tim i ishrana koja uključuje pravoslavne postove na papiru veoma dobra.</p>
<p>Dok načelno post predviđa odlične efekte po zdravlje i dugovečnost, današnje društvo omogućilo je post bez odricanja. U prodavnicama se mogu naći praktično svi proizvodi u svojoj posnoj varijaciji poput: kačkavalja, keksa, čokolade, mleka, zamena za meso… Deluje mi da se na ovaj način duhovni čin posta obesmislio. Međutim, za mene kao savetnika za ishranu mnogo je bitnije što su se mnogi pozitivni aspekti posta po zdravlje potpuno izgubili. Klijenti koji poste često tokom posta unose još veći broj visoko prerađenih proizvoda, zajedno sa velikim količinama testa, hlebova i drugih proizvoda od brašna, čime, umesto poboljšanja, dobijaju dalje pogoršanje svoje već problematične ishrane. Stoga, <a    rel="nofollow" href="https://link.springer.com/article/10.1007/s00394-017-1534-8">efekti pravoslavnog posta</a> na zdravlje personalno variraju i nema konkretnog zaključka da li je post pozitivan ili negativan po zdravlje.</p>
</SquareImage>
<Conclusion>Post u hrišćanstvu može biti zdrav način ishrane sličan mediteranskoj dijeti, ali samo ako se praktikuje promišljeno, a u suprotnom, post može izgubiti i duhovni i zdravstveni benefit</Conclusion>
<h3>Islam i ramazanski post</h3>
<p>Jedna od najpoznatijih i najstrukturiranijih formi posta u svetu je ramazanski post koji praktikuje više od 1,8 milijardi ljudi na svetu. Ramazanski post je jedan od pet stožera islamske religije i pored hrane uključuje i suzdržavanje od pića i drugog ponašanja (pušenja, seksa, preteranog zadovoljstva). Tokom meseca Ramazana (29-30 dana) vernici praktikuju post od izlaska do zalaska sunca, pa je vreme kada poste u toku dana različito za različite delove sveta.</p>
<SquareImageLeft src={SixthImage} alt="Description">
<p>Kao i u hrišćanstvu, post u islamu nije samo fizičko odricanje već i način samokontrole, zahvalnosti i duhovne discipline. Dodatno, tokom Ramazana, vernici se trude da pročitaju čitav Kuran, kao i da razumeju i primene pročitano u svakodnevici.</p>
<p>Striktno, sa aspekta ishrane, ramazanski post najviše podseća na režim periodičnog gladovanja, konkretno 12:12 ili post naizmeničnim danima. Većina režima periodičnog gladovanja ima pozitivne efekte po zdravlje, o čemu više možete pročitati u <a href="/science/blog_post/2">ovom tekstu</a>. Značajna razlika je što se tokom ramazanskog posta osim hrane ograničava i unos vode. Ova restrikcija može <a    rel="nofollow" href="https://www.frontiersin.org/journals/nutrition/articles/10.3389/fnut.2022.922544/full">izazvati zdravstvene probleme</a> jer je za većinu ljudi adekvatan unos vode u toku normalnog dana već izazov, čak i kada mogu da piju vodu u toku celog dana. I pored ovoga, ramazanski post je <a    rel="nofollow" href="https://pubmed.ncbi.nlm.nih.gov/20424438/">dokazano</a> bezbedan po sve zdrave odrasle individue, a grupe ljudi na koje bi mogao negativno da utiče izuzete su od obaveze posta: deca, porodilje, bolesnici, oni koji imaju teške poslove, stari i trudnice.</p>
</SquareImageLeft>
<Conclusion>Ramazanski post je duhovna i fizička praksa slična periodičnom gladovanju, sa dokazanim benefitima za zdrave osobe, ali zahteva pažljiv pristup zbog potpunog uzdržavanja od vode tokom dana.</Conclusion>
<h3>Danijelov post</h3>
<p>Danijelov post je poseban oblik hrišćanskog posta inspirisan biblijskom pričom o proroku Danijelu, koji se uzdržavao od „kraljevske hrane“ i konzumirao samo hranu biljnog porekla i vodu kako bi ostao veran svojim uverenjima. Opisan u knjizi proroka Danijela u Starom zavetu, ovaj post se često tumači kao primer discipline, duhovne posvećenosti i vernosti Bogu.</p>
<p>Danijelov post praktikuje se uglavnom među protestantskim grupama kao način duhovne obnove, uzdržavanja i posvećenosti molitvi i veri. Ovaj biblijski post <a rel="nofollow" href="https://link.springer.com/article/10.1186/1475-2891-9-57">može</a> da traje između 10 i 40 dana, ali je 21 dan posta najčešća praksa. Tokom posta, vernici se suzdržavaju od unosa hrane životinjskog porekla, prerađenih šećera, aditiva, prezervanasa, zaslađivača, boja, aroma, kofeina i alkohola, odnosno sve prerađene hrane.</p>
<p>Iz perspektive ishrane, ovaj post liči na vegeterijansku varijaciju čistog jedenja (clean eating) i donosi značajne zdravstvene koristi. Ovi pozitivni efekti su <a rel="nofollow" href="https://pubmed.ncbi.nlm.nih.gov/20815907/">dokazani</a> kroz smanjenje rizika za razvoj metaboličkih i kardiovaskularnih bolesti, kao i <a    rel="nofollow" href="https://pmc.ncbi.nlm.nih.gov/articles/PMC3517900/">unapređenje različitih krvnih parametara</a> uključujući LDL, LDL:HDL, nivo glukoze i insulina, HOMA-IR, krvnog pritiska i ukupne telesne težine. Treba spomenuti da su sve studije o ovoj vrsti posta rađene na malim grupama ljudi i da su značajno ređe u odnosu na studije koje se bave ramazanskim i pravoslavnim postom.</p>

<h3>Jevrejski Yom Kippur post</h3>
<SquareImage src={SeventhImage} alt="Description">
<p><em>Yom Kippur</em>, poznat kao Dan pomirenja, najsvetiji je dan u judaizmu i obeležava se strogim postom i molitvom. Ovaj praznik slavi se desetog dana meseca <em>Tišrija </em>i predstavlja kulminaciju perioda introspekcije i pokajanja poznatog kao <em>Deset dana pokajanja</em>.</p>
<p>Post na Dan pomirenja traje 25 sati i uključuje potpuno uzdržavanje od hrane i pića, uključujući i vodu. Osim toga, vernici se uzdržavaju i od drugih telesnih uživanja, poput kupanja, upotrebe parfema i nošenja kožnih cipela. Kao i kod drugih religijskih postova, cilj posta je, pored fizičkog uzdržavanja, viša duhovna svrha kroz unutrašnje preispitivanje, traženje oproštaja i duhovno pročišćenje.</p>
<p>Sa aspekta nutricije, ovaj post je vrlo intenzivan, ali kratkotrajan, pa njegovi efekti ne mogu imati preveliki, ni pozitivan, ni negativan značaj za telo. Najviše liči na režime produženog gladovanja koji mogu trajati od 24 do 72 sata. Razlika kod ovog religijskog posta je zabrana vode, što može imati umerene negativne efekte na zdravlje. Nisam pronašao radove koji su ispitivali zdravstvene efekte ovog posta, ali znamo da produženo gladovanje ima pozitivan efekat, dok nedostatak vode ima određene mane.</p>
</SquareImage>
<h3>Budistički post</h3>
<p>Za razliku od monoteističkih religija, o kojima smo pričali do sada, u budizmu post nema svrhu molitve i pokajanja, već je doživljen kao sredstvo lične discipline, pročišćenja i prosvetljenja. Budisti post posmatraju kao način kontrole želja, smanjenja vezanosti za materijalni svet i dubljeg razumevanja prirode patnje i prolaznosti. U nekim slučajevima, budisti poste kako bi razvili saosećanje prema onima koji pate od gladi, podsećajući se na prolaznost i nepostojanost telesnih potreba.</p>
<SquareImageLeft src={EightImage} alt="Description">
<p>Budistički post može značiti različite stvari u zavisnosti od škole budizma i regiona u kojem ljudi žive. Česta praksa u budizmu je celoživotno vegeterijanstvo, posebno među monasima, pa je to još jedna od restrikcija koja postoji u njihovoj ishrani pored posta. U <em>theravada </em>budizmu, monasi praktikuju post tako što ne jedu nakon podneva, verujući da će im tako um ostati bistar, a telo neopterećeno. U <em>mahayana </em>tradiciji, određeni budistički zaveti uključuju specifične dane posta, poput osmog, četrnaestog i petnaestog dana lunarnog meseca, kada se vernici uzdržavaju od hrane, mesa i drugih zadovoljstava. <em>Vajrayana </em>budizam ima specifične ritualne postove i nije nužno vegetarijanski.</p>
<p>Ako budističke postove posmatramo samo kao režime ishrane, svi liče na različite forme periodičnog gladovanja, o kojima je do sada bilo dosta reči. Studije se uglavnom fokusiraju na ispitivanje uticaja celoživotnog vegeterijanskog načina ishrane pre nego povremenih kratkotrajnih postova. Tako je <a rel="nofollow" href="https://search.informit.org/doi/abs/10.3316/informit.683799447037578">studija</a> urađena 2009. godine ispitivala razliku u sastavu tela budističkih monahinja koje su vegeterijanci u odnosu na katoličke monahinje koje su omnivori. Zaključili su da nema većih razlika u sastavu tela monahinja, kao i da postoje neke umerene prednosti vegeterijanskog načina ishrane.</p>
</SquareImageLeft>
<h2>Fizički efekti posta</h2>
<p>Na ovom mestu trebalo je da se nađe deo o fizičkim efektima posta, ali sam posle nekoliko dana istraživanja upao u zečiju rupu i napisao sledeće podnaslove: <em>Metabolizam masti; Metabolizam šećera; Metabolizam proteina; Uticaj na ukupnu telesnu težinu i BMI; Krvni pritisak</em>. Ovo ne samo da je bilo jako obimno, već i zaista izrazito dosadno. Evo ukratko šta sam saznao na ovu temu, ali sve uzmite sa rezervom jer postoji mnogo načina posta i različitih merenja koja su pri tom korišćena:</p>
<ul>
    <li>
        <p>Povremeni post može da smanji viscelarne (loše) masti u telu.</p>
    </li>
    <li>
        <p>Post smanjuje HbA1c, marker šećera u krvi pa je dobar za ljude sa preddijabetesom ili metaboličkim sindromom.</p>
    </li>
    <li>
        <p>Post ne smanjuje mišićnu masu značajno, a kada je smanjuje luči se i hormon rasta koji će da ubrza izgradnju mišića nakon posta.</p>
    </li>
    <li>
        <p>Post doprinosi smanjenju telesne mase… Koliko tačno? Pa zavisi od posta, ali neki dobar broj je od 3% do 8% u periodu od 3 do 24 nedelje.</p>
    </li>
    <li>
        <p>Post poboljšava kompoziciju tela smanjujući udeo masti, dok čuva mišićnu masu</p>
    </li>
    <li>
        <p>Post ima povoljan uticaj na krvni pritisak (posebno kod ljudi sa visokim pritiskom)</p>
    </li>
</ul>

<h2>Psihički efekti posta</h2>
<p>Post ima složen uticaj na psihološko stanje ljudi koje zavisi od različitih faktora, uključujući dužinu posta, individualne sklonosti, prethodna iskustva i razloge zbog kojih osoba odlučuje da posti. Upravo iz ovog razloga nije moguće dati konačan odgovor na pitanje da li je post dobar ili loš. Prema radovima, kratkotrajni postovi, u zavisnosti od brojnih faktora, mogu izazvati i pozitivne i negativne emocije, dok dugotrajniji postovi uglavnom prate specifičan obrazac emocionalnih promena.</p>
<p>Interesantno <a    rel="nofollow" href="https://pmc.ncbi.nlm.nih.gov/articles/PMC4992682/">israživanje</a> koje je pratilo raspoloženje 52 zdrave žene pokazalo je da pri kratkom postu od 18 sati dolazi do značajnih promena raspoloženja. Sa jedne strane, žene su prijavile da je post postajao teži kako su postajale gladnije, ali su takođe prijavile osećaj povećanog osećaja kontrole, ponosa i postignuća nakon uspešno sprovedenog posta.</p>
<RectangleImage src= {NinethImage} alt="Description"></RectangleImage>
<p>U sportu, post je često način za postizanje brzog gubitka kilograma i „upadanja” u željenu kategoriju. <a    rel="nofollow" href="https://europepmc.org/article/med/9588618">Studija</a> koja je pratila mlade rvače zaključila je da nakon rigoroznih režima za smanjenje težine (više od 5% ukupne težine) dolazi do pada kognitivnih sposobnosti i raspoloženja. Ovo može biti povezano sa padom nivoa šećera u krvi, ukupne zapremine krvi, poremećajem sna i dosta drugih faktora. Druga <a    rel="nofollow" href="https://pmc.ncbi.nlm.nih.gov/articles/PMC5153500/">studija</a>koja je pratila dizače tegova zaključila je da je tokom 48 sati posta došlo do povećanja besa, ali su se istovremeno poboljšale određene kognitivne sposobnosti, a nije došlo do promene na polju pamćenja i učenja novih stvari. Rigorozni režimi za smanjenje težine podrazumevaju izbacivanje vode, pa nedostatak hidracije može biti odgovoran za kontradiktorne rezultate ovih i drugih studija.</p>
<p>Zaključio bih da su efekti gladovanja na naše raspoloženje i psihički status najviše povezani sa našim doživljajem posta i nemaju jasan biohemijski mehanizam. Dokaz za ovo dajem u odličnom <a   rel="nofollow" href="https://www.sciencedirect.com/science/article/pii/S0002916523241593">istraživanju</a> koje je dokazalo da kada ljudi gladuju dva dana, ali toga nisu svesni, nema opadanja kognitivnih performansi, aktivnosti, sna, niti promene raspoloženja. Ovime <a    rel="nofollow" href="https://pmc.ncbi.nlm.nih.gov/articles/PMC8754590/">dokazujemo</a> da su faktori koji utiču na psihičke efekte posta u suštini zavisni samo od naše percepcije, odnosno:</p>
<ul>
    <li>
        <p><strong>Religijski kontekst</strong> - Ljudi koji poste iz verskih razloga često imaju pozitivnije iskustvo, jer post za njih ima dublji smisao i prihvataju ga sa manje stresa. Dok, oni koji poste iz drugih razloga mogu osećati veći mentalni pritisak i nelagodnost.</p>
    </li>
    <li>
        <p><strong>Emocionalna kontrola</strong> - Post zahteva određeni nivo kognitivnog angažmana i samokontrole, što može biti izazovno, ali i korisno za jačanje osećaja discipline i unutrašnje snage.</p>
    </li>
    <li>
        <p><strong>Prethodno iskustvo</strong> - Osobe koje su ranije postile obično imaju manje negativnih efekata, dok oni bez prethodnog iskustva često osećaju veću anksioznost i umor tokom prvih dana posta.</p>
    </li>
    <li>
        <p><strong>Početno mentalno stanje</strong> - Osobe koje već pate od stresa, anksioznosti ili depresije mogu doživeti poboljšanje raspoloženja tokom posta, dok kod onih koji su već u stabilnom emotivnom stanju često nema značajnih promena.</p>
    </li>
</ul>

<Conclusion>Efekti posta na psihičko stanje zavise pre svega od lične percepcije, konteksta i iskustva, a ne od samog gladovanja. Duhovni smisao i prethodna iskustva često ublažavaju negativne efekte i pojačavaju osećaj kontrole i postignuća</Conclusion>

<h2>Post kao alat u savremenom životu</h2>
<p>Danas su hrana i mogućnost uživanja u njoj dostupni u svakom trenutku, zbog čega se post doživljava kao nešto zastarelo i nepraktično. Međutim, naše shvatanje ljudskog tela i rastući broj istraživanja ukazuje da post može biti moćan alat za rešavanje čitavog niza problema modernog života. Bilo da je reč o fizičkom i mentalnom zdravlju, treningu volje ili čak usporavanju starenja, gladovanje ima potencijal da pomogne i poveže čoveka sa sopstvenim potrebama i telom.</p>
<SquareImage src={TenthImage} alt="Description">
<p>Moderna hrana ne samo da je stalno dostupna, već je i uglavnom obogaćena dodatnom energijom kroz dodate masti i šećere. Ovo je izazvalo pravu epidemiju hroničnog trovanja kalorijama, koje je dalje dovelo do ogromnog porasta preventivnih bolesti uključujući insulinsku rezistenciju, metaboličke poremećaje, bolesti srca i drugo. Takođe, problemi sa nezadovoljstvom svojim izgledom, prejedanjem, depresijom i drugim mentalnim problemima mogu se ispratiti do istog izvora. Post se može lako iskoristiti za restartovanje ovih navika i pružanje predaha našem organizmu od neprekidne obrade hrane, čime se omogućava bolje upravljanje apetitom i poboljšava odnos prema hrani.</p>
<p>Kroz kontrolisano uskraćivanje hrane, gladovanje može pomoći u razvijanju discipline, jačanju samokontrole i poboljšanju fokusa. Kao neko ko često praktikuje gladovanje mogu garantovati da kada ste stvarno gladni, makar i svojom voljom, svi drugi problemi postaju manji i lakši. Rekao bih da post omogućava da napravimo korak unazad i objektivnije razmotrimo svoje navike, odluke i život u celini. Ukoliko odlučite da isprobate neki oblik produženog gladovanja sagledaćete svoju zavisnost od žvakanja i shvatiti da često hranom zapravo zatrpavamo neke probleme koji nisu glad.</p>
</SquareImage>
<p>Još jedna značajna prednost posta je što ne zahteva nikakvu pripremu, jednostavan je i lako se prilagođava svim obavezama i nepredvidivosti života. Ovo ga čini izuzetno fleksibilnim alatom za rad na svojim navikama i poboljšanjima ishrane. Više klijenata sa kojima radim nema vremena da se zaista posveti svojoj ishrani, ali kod povremenog posta, sve što treba da urade je da neko vreme ne jedu, pa osim nedostatka volje ili želje ne postoji ni jedna druga prepreka.</p>
<Conclusion>U savremenom svetu prejedanja i stalne dostupnosti hrane, post se pokazuje kao jednostavan, efikasan i moćan alat za reset navika, poboljšanje zdravlja i jačanje samodiscipline</Conclusion>
<h2>Zaključak</h2>
<p>Post nije samo drevna tradicija ili trenutni trend, on je duboko ukorenjen u ljudskoj biologiji, evoluciji i kulturi. Od davnina je predstavljao način prilagođavanja oskudici, sredstvo duhovnog rasta i alat za očuvanje zdravlja. Danas, u svetu obilja i neprekidne dostupnosti hrane, njegova vrednost ni najmanje nije izgubila na značaju.</p>
<p>Kao metabolički mehanizam, post aktivira procese koji optimizuju sagorevanje masti, stabilizuju nivo šećera u krvi i poboljšavaju metaboličku fleksibilnost. Pored toga, pokazuje potencijalne benefite za dugovečnost i prevenciju hroničnih bolesti. Međutim, njegov uticaj nije samo fiziološki, on može poboljšati mentalno zdravlje, pomoći u disciplinovanju i pojačati kontrolu nad sopstvenim navikama.</p>
<p>Psihološki efekti posta su kompleksni i variraju od osobe do osobe. Dok nekima donosi osećaj postignuća i unutrašnje snage, drugima može predstavljati izazov. Razlika je u kontekstu u koji osoba stavlja post. Kada se praktikuje sa svrhom i razumevanjem, može biti moćan alat za prepoznavanje sopstvenih potreba, emocija i zavisnosti od hrane.</p>
<p>U savremenom načinu života, post može poslužiti kao jednostavna, fleksibilna i efikasna strategija za resetovanje metabolizma, poboljšanje odnosa prema hrani i jačanje mentalne otpornosti. Ovaj period gladovanja ne mora biti dugotrajan ili ekstreman, jer čak i povremeni post koji traje manje od 24 sata donosi značajne koristi.</p>
<p>Na kraju, post nije magično rešenje niti univerzalna praksa koja odgovara svima. On je alat, a kao i svaki alat, njegova vrednost zavisi od načina na koji se koristi. Iz ovog razloga gladovanje ne preporučujem većini svojih klijenata, nekada jer mislim da će im biti preteško, a nekada jer se brinem da će preterivati i na neki način zloupotrebiti post kao lak način da se osete bolje povodom loše ishrane. Međutim, kada se primeni svrsishodno i prilagodi individualnim potrebama, post može doprineti zdravlju, fokusu i boljoj kontroli sopstvenog tela i uma.</p>
<p>Jo&scaron; zanimljivih tekstova možete pročitati na mom <a href="/science/">BLOG-u</a>.</p>

    </div>
    )
}
export default BlogPost14