import React from 'react'
import { SquareImage, Conclusion, RectangleImage } from '../blog_visuals.js';

import FirstImage from '../blog_images/kragujevac_nis/img1.jpg'
import SecondImage from '../blog_images/kragujevac_nis/img2.jpg'
import ThirdImage from '../blog_images/kragujevac_nis/img3.jpg'
import FourthImage from '../blog_images/kragujevac_nis/img4.jpg'
import FifthImage from '../blog_images/kragujevac_nis/img5.jpg'


const BlogPost13 = () => {
    return (
    <div style={{width:'100VW', overflowWrap: 'anywhere', paddingTop:'15px'}}>
        
        <p>Ukoliko iz bilo kog razloga odlučite da se 25. februara u jutarnjim časovima pešice uputite iz Kragujevca ka Nišu, ovaj tekst će vam pomoći da se adekvatno pripremite i da tokom puta imate dovoljno energije da stignete do kraja. Ovaj tekst bavi se ishranom, hidratacijom i suplementacijom tokom ovog zahtevnog putovanja.</p>
<p>Pokušao sam da saznam tačnu rutu kako bih preciznije odredio dužinu puta i teren, ali u trenutku pisanja ove informacije i dalje nisu dostupne. Bez obzira na konačnu trasu, putovanje od Kragujevca do Niša iznosi manje od 150 kilometara, a plan je da se stigne u Niš za četiri puna dana, sa četiri noćenja. To znači da je dnevno potrebno preći između 30 i 40 kilometara. Većina puta će biti asfaltirana, ali će se koristiti i lokalni putevi, a moguće i makadamske staze. U zavisnosti od rute, teren će sadržati blage do umerene uzbrdice i nizbrdice.</p>
<p>Dodatni izazov mogu predstavljati temperatura, kiša i vetar. Prosečna temperatura krajem februara kreće se između 0 i 12 stepeni, dok trenutne prognoze predviđaju relativno toplo vreme, sa dnevnim minimumom oko 5 stepeni i maksimumom između 6 i 13 stepeni. Može se očekivati delimično oblačno vreme, povremeni kratkotrajni pljuskovi i lagani vetar, što su ukupno gledano prilično dobri uslovi za dugu šetnju.</p>
<RectangleImage src= {FirstImage} alt="Description"></RectangleImage>
<p>Pešačenje 30 do 40 kilometara dnevno predstavlja ozbiljan fizički napor i spada u dugotrajnu aerobnu aktivnost. Ova vrsta napora posebno je izazovna za osobe koje nisu u formi. Najveće opterećenje biće na donjem delu tela, posebno listovima, kvadricepsima, zadnjoj loži i stopalima. Kako bi se smanjio stres na zglobove (kolena, kukove i članke), savetuje se izbor udobne obuće sa mekanim đonom, jer će se veći deo puta prelaziti asfaltom.</p>
<p>U zavisnosti od telesne mase i tempa hoda, dnevna kalorijska potrošnja može iznositi između 3.500 i 5.000 kcal. Zbog toga je dovoljan unos hrane ključan kako bi se nadoknadile izgubljene kalorije i osiguralo optimalno funkcionisanje organizma. Takođe, postoji povećan rizik od dehidratacije i poremećaja elektrolita, čak i pri nižim temperaturama. Dovoljan unos tečnosti i elektrolita ključan je za uspešan završetak putovanja.</p>

<h2>Ishrana tokom pešačenja</h2>
<p>Dugotrajno pešačenje zahteva visok unos energije. Dok u normalnim uslovima ne insistiram na razmišljanju o izvorima kalorija, niti ukupnom kalorijskom unosu, u ovoj situaciji je bitno da postoji dobro izbalansiran unos osnovnih nutrijenata (ugljenih hidrata, proteina i masti) kako bi se održala energija, smanjio umor i ubrzao oporavak. Generalna preporuka za ovaj put bila bi da se iz ugljenih hidrata dobija oko 55% kalorija, iz proteina oko 20%, a iz masti oko 25%. Međutim, mnogo praktičniji savet bi bio, probajte da ne jedete samo nekoliko namirnica, već mešajte grupe namirnica (meso, povrće, testo, voće, pirinač…).</p>
<p>Na putu očekujem da će hrana biti dostupna, kako iz prodavnica i restorana, tako i iz donacija, pa nije neophodno nositi velike količine sa sobom, ali je i dalje korisno imati rezervne grickalice i energetski guste namirnice za situacije kada hrana nije dostupna.</p>

<h3>Ugljeni hidrati (Šećeri)</h3>
<p>Ugljeni hidrati (UH) su glavni izvor energije. Ukusni su, dostupni i brzo se usvajaju. Ovakav tip aktivnosti zahtevaće veliki unos šećera jer će se rezerve glikogena brzo trošiti, pa je potrebno redovno ih nadoknađivati. Poželjno je kombinovati spore (skrobaste) i brze (proste šećere) izvore. Izvori UH:</p>
<ul>
    <SquareImage src={SecondImage} alt="Description">
    <li>
        <p><strong>Krompir, pirinač i testenina</strong> - Kada je moguće ovi izvori su energetski bogati i kombinacija prostih i složenih UH, što znači da dugo oslobađaju energiju. Ovako nešto će biti moguće jesti u restoranima ili iz donacija, ali su nepraktični za nošenje</p>
    </li>
    <li>
        <p><strong>Suvo voće (urme, suve kajsije, suvo grožđe)</strong> - Laki su za nošenje, veoma bogati energijom i nutrijentima, ukusni. U velikim količinama mogu izazvati stomačne tegobe, tako da nemojte preterivati</p>
    </li>
    <li>
        <p><strong>Krekeri - </strong>Laki za nošenje, ne kvare se, dobar izvor soli i UH; ali su suvi i bez značajnih mikronutrijenata</p>
    </li>
    <li>
        <p><strong>Kiflice i druga testa - </strong>Očekujem da će biti dosta donacija u vidu domaćih proizvoda od testa. Ovo su izvori UH, ali ne sadrže praktično ništa drugo. Sporo se kvare, pa je zgodno pokupiti ih i spakovati za kasnije. Jedite ih, ali nemojte da preterujete</p>
    </li>
    </SquareImage>
    <li>
        <p><strong>Hleb i lisnata peciva - </strong>Dostupni su na mnogo mesta (prodavnice i pekare) , nisu kvalitetni kao prethodni izvori, ali u ovom slučaju su prihvatljiva opcija, posebno uz meso ili drugu masnu hranu</p>
    </li>
    <li>
        <p><strong>Sveže voće</strong> - Pored UH sadrži vitamine i minerale, ali je relativno siromašno energijom, pa ga ne treba nositi sa sobom, već iskoristiti svaku priliku da se uzme i odmah pojede</p>
    </li>
    <li>
        <p><strong>Kukuruzne i pirinčane galete - </strong>Lake za nošenje, brzo se vare i daju brzu energiju, ali ne sadrže puno korisnih sastojaka, a ukoliko niste naviknuti na njih, zbog puno vlakana mogu izazvati probleme sa stolicom</p>
    </li>
    <li>
        <p><strong>Kandirano voće (ananas, papaja, mango…)</strong> - Imaju puno prostih šećera, ali i nešto dobrih sastojaka. Takođe su laki i praktični za nošenje i izuzetno bogati energijom</p>
    </li>
    <li>
        <p><strong>Slaniši (smoki, čips i slično) - </strong>Energetski su gusti i slani što je dobro za elektrolite, ali imaju dosta loših masti, izazivaju žeđ i pružaju samo kratkotrajnu energiju</p>
    </li>
    <li>
        <p><strong>Slatkiši</strong> - Brzi, dostupni, voljeni, ali nemaju ništa osim šećera. Dobri su za hitne situacije, pri većim preprekama i za podizanje morala</p>
    </li>
    <li>
        <p><strong>Med i džem</strong> - Slično kao slatkiši</p>
    </li>
     <li>
     <p><strong>Sokovi i energetska pića </strong>- Najbrža moguća energija, ali ne samo da ne sadrže nutrijente već mogu da ometaju apsorpciju i da izazovu pad šećera kasnije. Ukoliko želite da pijete sokove, budite jako umereni</p>
    </li>
</ul>

<h3>Proteini</h3>
<p>Proteini su neophodni za sprečavanje razgradnje i oporavak mišića tokom višednevnog hoda. Zbog konstantnog stresa na mišiće, dovoljan unos proteina je od ogromne važnosti. Izvori proteina su:</p>
<ul>
    <li>
        <p><strong>Orašasti plodovi (orah, badem, lešnik, indijski orah, pistaći, kikiriki)</strong> - Dobar izvor proteina i zdravih masti, energetski su veoma bogati, laki za nošenje, pa su jedna od najboljih opcija za ovo putovanje. Nemojte da preterate jer mogu da naprave probleme sa varenjem u većim količinama</p>
    </li>
    <SquareImage src={ThirdImage} alt="Description">
    <li>
        <p><strong>Kikiriki puter</strong> - Slično kao i orašasti plodovi sadrži puno proteina i masti, energetski gust, lako se nosi, a dodatno se može mazati ili jesti direktno. Jedan od idealnih proizvoda za poneti</p>
    </li>
    <li>
        <p><strong>Sušeno meso - </strong>Dugo traje, ima puno proteina, slano je što je dobro za elektrolite. Nadam se da će biti ovakvih donacija, ali nije loše poneti</p>
    </li>
    <li>
        <p><strong>Čvarci i pečenje - </strong>Nije dugotrajan proizvod, da će se brzo pojesti. Hrana bogata energijom i proteinima. Očekujem da će biti u donacijama i nema potrebe da se nosi</p>
    </li>
    
    <li>
        <p><strong>Kačkavalj i tvrdi sir</strong> - Sadrže mnogo proteina i masti, ukusni i imaju elektrolite, a problem kvarljivosti je delimično rešen hladnim vremenom</p>
    </li>
    <li>
        <p><strong>Kuvana jaja</strong> - Jedan od najboljih izvora proteina i mikronutijenata, mogu trajati nekoliko dana van frižidera, ali budite oprezni</p>
    </li>
    
    <li>
        <p><strong>Tunjevina i konzervirana riba</strong> - Dobri proteini i lako se kombinuje sa svime. Zgodno za nošenje, ali nezgodno za jelo u putu</p>
    </li>
    </SquareImage>
    <li>
        <p><strong>Proteini (surutke ili biljni proteini)</strong> - Jednostavan način da se osigura dovoljan unos proteina ali zahteva šejker i pripremu, a ukoliko niste isprobali i navikli na konkretnu marku proteina, može doći do digestivnih problema</p>

    </li>
</ul>
    
<h3>Masti</h3>
<p>Zbog niskog intenziteta i dugog trajanja fizičkog napora, telo će većinski preći na korišćenje masti kao izvora energije, barem u nekim delovima etape. Dok inače uglavnom izbegavamo preteran unos masti, na ovom putu će biti teško preterati sa njima, tako da se opustite. Većina kvalitetnih izvora masti već je opisana u delu o proteinima, pa ću ih ovde samo ponovo izlistati:</p>
<ul>
    <li>
        <p><strong>Orašasti plodovi</strong>
        </p>
    </li>
    <li>
        <p><strong>Semenke (suncokret, bundeva)</strong> - Slično kao orašasti plodovi</p>
    </li>
    <li>
        <p><strong>Maslinovo ulje (u bočicama)</strong> - Malo nezgodno za nošenje, ali može biti korisno i lako se dodaje uz drugu hranu, kao kalorijski dodatak</p>
    </li>
    <li>
        <p><strong>Avokado </strong>-<strong> </strong>Dobre masti i elektroliti, lako se vari, ali je kvarljiv, nezgodan za nošenje i etički problematičan</p>
    </li>
    <li>
        <p><strong>Maslac, kikiriki puter, puter od badema i sl.</strong>
        </p>
    </li>
    <li>
        <p><strong>Suvo meso, čvarci, pečenje</strong>
        </p>
    </li>
    <li>
        <p><strong>Tunjevina i konzervirana riba</strong>
        </p>
    </li>
    <li>
        <p><strong>Kačkavalj, sir i drugi punomasni mlečni proizvodi</strong>
        </p>
    </li>
</ul>
<Conclusion>Održavanje energije i brzi oporavak zahtevaju dobro izbalansiran unos ugljenih hidrata, proteina i masti. Hrana će biti dostupna usput, ali je korisno imati lagane i energetski bogate namirnice pri ruci kako biste izbegli padove energije i nepotreban umor</Conclusion>


<h2>Hidratacija i tečnosti tokom pešačenja</h2>
<p>Duga fizička aktivnost, čak i po umerenim temperaturama, izaziva gubitak tečnosti kroz disanje i znoj. Iako telo može da funkcioniše i sa veoma malo vode, performanse značajno opadaju već pri blagoj dehidrataciji. Dehidratacija dovodi do pojačanog umora, grčeva, vrtoglavice, slabosti i smanjenja koncentracije, tako da dobra hidratacija mora da bude prioritet. Osim vode, kroz znoj i disanje se gubi velika količina elektrolita, pa se i oni moraju nadoknaditi hranom ili tečnostima.</p>
 <RectangleImage src= {FourthImage} alt="Description"></RectangleImage>
<h3>Koliko tečnosti treba?</h3>
<p>Količina tečnosti zavisi od kilaže, temperature i intenziteta aktivnosti. Hodanje je umerena fizička aktivnost, a temperatura će biti niska, međutim, prelaziće se velika distanca od 30 do 40 kilometara dnevno. Okvirna procena je:</p>
<ul>
    <li>
        <p>Muškarac (80 kg) od 4,5 do 6 litara dnevno</p>
    </li>
    <li>
        <p>Žena (60 kg) od 3,5 do 5 litara dnevno</p>
    </li>
</ul>
<p>Najbolje je piti 200 do 300 ml vode na svakih 20 do 30 minuta kako bi hidratacija bila konstantna i efikasna.</p>
<h3>Tečnosti</h3>
<ul>
    <li>
        <p><strong>Voda</strong> - Osnovna i najvažnija stvar za ovo putovanje. Ima je svuda pa nema potrebe za nošenjem velike količine vode. Zapamtite da voda ne sadrži elektrolite, pa ako gubite elektrolite kroz znoj, a unosite samo čistu vodu doćiće do disbalansa elektrolita i čitavog niza problema uzrokovanih njime</p>
    </li>
    <li>
        <p><strong>Surutka </strong>- Ovo je prava elektrolitska bomba i dobar način da se rehidrirate, idostupna je u prodavnicama, a možda i kroz donacije. Sa druge strane, svi klijenti kojima sam je spomenuo su negodovali, tako da nije omiljena, a ni neophodna stvar</p>
    </li>
    <li>
        <p><strong>Mineralna voda</strong> - sadrži nešto elektrolita, što pomaže u održavanju ravnoteže, ali je manje dostupna od obične</p>
    </li>
    <li>
        <p><strong>Izotonični napici</strong> (sportski napici) - Obezbeđuju hidrataciju, elektrolite i malo UH, brzo se apsorbuju i usporavaju umor, ali nisu dostupni svuda i koštaju relativno puno u odnosu na druge opcije</p>
    </li>
    <li>
        <p><strong>Čaj (zeleni, crni)</strong> - Dobar izvor antioksidanasa, a sadrži i nešto kofeina. Međutim, diuretik je, što znači da ubrzava gubitak tečnosti ako se pije u velikim količinama</p>
    </li>
    <li>
        <p><strong>Čaj (biljni)</strong> - U zavisnosti od vrste čaja može imati blago pozitivan ili negativan uticaj. Ukoliko ste u prilici da popijete vruć čaj, popijte ga</p>
    </li>
    <li>
        <p><strong>Supa</strong> - Bogata elektrolitima, pomaže rehidrataciju i varenje, ali je praktično nemoguće nositi gotovu supu, a može biti teško naći je usput. Iskoristite svaku priliku da pojedete tanjir supe</p>
    </li>
    <li>
        <p><strong>Mleko i mlečni napici</strong> - Sadrže proteine i elektrolite i mogu pomoći rehidrataciji, ali su teški za varenje i mogu izazvati nadutost</p>
    </li>
    <li>
        <p><strong>Sokovi (voćni)</strong> - Daju UH i vitamine, brzo podižu energiju što je pozitivno na kratke staze, ali loše na duge. Ukoliko želite da ih pijete, budite umereni</p>
    </li>
    <li>
        <p><strong>Sokovi (gazirani)</strong> - Zbog velike količine šećera i kofeina mogu ubrzati dehidrataciju, a osim šećera ne sadrže korisne nutrijente. Povremeno je u redu, ali ovo je loš izbor za hidrataciju</p>
    </li>
    <li>
        <p><strong>Kafa i instant kafa</strong> - Ima blaga stimulativna svojstva, topla je i laka je za nošenje i pripremu. Sa druge strane, ima blagi diuretički efekat. U redu je koristiti kafu umereno, ali ne preterivati, jedna do dve šoljice dnevno su prihvatljive</p>
    </li>
    <li>
        <p><strong>Alkohol </strong>- Može pomoći pri opuštanju nakon napornog dana, ali benefiti tu staju. Alkohol otežava hidrataciju, ubrzava gubitak elektrolita, usporava oporavak mišića i uzrokuje umor sledećeg dana. Najbolje bi bilo potpuno ga izbegavati, a ako se konzumira, neka to bude umereno i isključivo na kraju dana</p>
    </li>
</ul>
<SquareImage src={FifthImage} alt="Description">
<h3>6 bitnih saveta</h3>
<ol>
    <li><strong>Pij vodu stalno, ne samo kada osetiš žeđ.</strong> Imamo veoma loš osećaj za žeđ i jednom kada je osetite već ste u problemu.</li>
    <li><strong>Kombinuj vodu sa elektrolitima.</strong> Osim vode povremeno popite slanu supu, surutku ili pojedite slanu hranu.</li>
    <li><strong>Ispoštujte svoju potrebu za slanom hranom.</strong> Imamo dobar osećaj za elektrolite, pa kada imamo pad elektrolita u krvi, telo traži slaniju hranu.</li>
    <li><strong>Imaj flašicu kod sebe.</strong> Povremeni mali unos vode (na svakih 15-20 minuta) je dobar način da ostanete hidrirani.</li>
    <li><strong>Izbegavajte previše slatkih sokova.</strong> Mogu da naprave probleme sa varenjem, oscilacije energije i ubrzanu dehidrataciju.</li>
    <li><strong>Obrati pažnju na boju urina.</strong> Urin treba da bude gotovo providan, tamnija boja ukazuje na nedostatak tečnosti.</li>
</ol>
</SquareImage>
<Conclusion>Redovan unos tečnosti i elektrolita ključan je za održavanje izdržljivosti i sprečavanje dehidratacije. Voda je osnovni izvor hidratacije, ali se povremeno treba nadoknaditi elektrolitima putem hrane, mineralne vode, supe ili sportskih napitaka</Conclusion>

<h2>Suplementacija tokom pešačenja</h2>
<p>Pravilna suplementacija može da pomogne u održavanju energije, smanjenju zamora i ubrzavanju oporavka. Da li su suplementi neophodni da bi se stiglo do cilja? Nisu, ali mogu da pomognu. Danas postoji puno sportskih suplemenata, ali ovo su najbitniji:</p>
<ul>
    <li>
        <p><strong>Dodatak elektrolita</strong> - Kao što je već napisano, elektroliti su neophodni za normalan rad tela. Dodatak elektrolita u vidu suplemenata pomoćiće da se izbegne dehidratacija i grčevi. Pomaže u održavanju ravnoteže telesnih tečnosti i regulaciji krvnog pritiska. Međutim, ukoliko se ne uzimaju pravilno, višak elektrolita može izazvati iste simptome kao i njihov manjak – iscrpljenost, vrtoglavicu i grčeve. Ovo je ključni suplement, a još je značajniji ukoliko ishrana i hidratacija nisu pravilni. Može se unositi kroz sportska pića, elektrolitne praškove ili tablete prema specifikaciji proizvođača</p>
    </li>
    <li>
        <p><strong>Magnezijum</strong> - Ovo je jedan od najčešćih suplemenata danas veoma popularan suplement. Adekvatna količina magnezijuma u krvi je neophodna za normalno funkcionisanje mišića i nervnog sistema, sprečava grčeve i smanjuje zamor. Magnezijum je redak u našoj ishrani pa je dobro suplementisati ga, posebno kod ljudi koji su skloni grčevima. Preporuka je da se uzima uveče 300 do 400 mg dnevno</p>
    </li>
    <li>
        <p><strong>Proteini </strong>- Surutka je najčešće korišćen proteinski suplement jer sadrži sve esencijalne aminokiseline. Smatram da može biti koristan jer je pri ovako visokim kalorijskim potrebama teško obezbediti dovoljan unos proteina. Biljni proteini, posebno mešavine, takođe mogu biti kompletni, ali su značajno skuplji po jedinici. Adekvatan unos proteina poboljšava oporavak mišića, a suplementi su laki za nošenje i unos, ali malo teži za pripremu, posebno ukoliko planirate da ih uzimate sa mlekom. Ukoliko inače ne uzimate proteinske suplemente, sada nije pravi trenutak za eksperimentisanje! Sve u svemu, nije neophodno, ali može biti korisno</p>
    </li>
    <li>
        <p><strong>BCAA </strong>- Postoji mnogo polemike, kako među stručnjacima tako i među pseudoekspertima, o efektima povećanog unosa određenih aminokiselina. Kratak odgovor je, da ako postoji razlika, ako razlika i postoji, nije značajna. Bolje je da unosite kompletne proteine, osim ako niste već primetili pozitivne efekte BCAA suplemenata</p>
    </li>
    <li>
        <p><strong>Vitamin C i antioksidansi</strong> - Pri fizičkom naporu dolazi do oksidativnog stresa koji antioksidansi uključujući i vitamin C mogu ublažiti. Takođe, ovi suplementi mogu podržati imuni sistem, što je važno zbog povećanog fizičkog stresa, hladnoće i promene uslova spavanja. Dnevna doza od 300 do 500 mg može biti korisna</p>
    </li>
    <li>
        <p><strong>Kofein </strong>- Ako ste navikli na kofeinske suplemente, mogu vam pomoći u održavanju fokusa i smanjenju umora. Tokom svog delovanja kofein će povećati subjektivni osećaj izdržljivosti. Sa druge strane kofein je diuretik, pa može izazvati dehidrataciju, i može negativno uticati na kvalitet sna. Za osobe koje ga redovno konzumiraju, preporučena doza je 50 do 100 mg na svaka 3 do 4 sata</p>
    </li>
    <li>
        <p><strong>Aspirin </strong>- Može pomoći kod upala mišića i bolova u zglobovima, naročito nakon više dana hodanja. Dobro je imati ga pri ruci</p>
    </li>
</ul>
<Conclusion>Iako suplementi nisu neophodni, mogu doprineti boljoj hidrataciji, bržem oporavku i smanjenju umora. Ključni dodaci uključuju elektrolite, magnezijum, proteine i vitamin C, dok je njihova upotreba individualna i zavisi od potreba</Conclusion>

<h2>Zaključak</h2>
<p>Dugo pešačenje od Kragujevca do Niša nije mali poduhvat, ali uz dobru ishranu, hidrataciju i suplementaciju može biti mnogo prijatnije iskustvo. Ovo putovanje zahteva veliki energetski unos, planiranje obroka i dovoljan unos vode i elektrolita. Uz to, pravilan izbor obuće i briga o mišićima i zglobovima smanjiće umor i rizik od povreda.</p>
<p>Kao neko ko će biti deo ovog putovanja, biću dostupan za sva pitanja,i pre i tokom puta. Ako imate bilo kakve nedoumice u vezi sa ishranom, hidratacijom i suplementima, slobodno mi se obratite. Moj cilj je da svako ko krene na ovaj put ima lepo i nezaboravno iskustvo, a da istovremeno vodi računa o svom telu i zdravlju. </p>
    
<p>Ukoliko već sada imate pitanje ili želite savet pre polaska, javite mi se <a href="https://www.instagram.com/dusan.aragonski/">INSTAGRAM</a> - u suprotnom, vidimo se na putu!</p>

    </div>
    )
}
export default BlogPost13