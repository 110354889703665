import * as React from 'react';
import Button from '@mui/material/Button';
import { Container } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const menuItems = [
  { text: 'Biografija', route: '/biografija' },
  { text: 'Usluge', route: '/usluge'},
  { text: 'Iskustva', route: '/iskustva' },
  { text: 'Blog', route: '/science' },
  { text: 'Pitanja', route: '/najcesca-pitanja'},
  { text: 'Kontakt', route: '/kontakt' },
];

function Menu() {
  const navigate = useNavigate();

  return (
    <Container style={{backgroundColor: ' #071c22', height: '55vH', justifyContent: 'space-around', padding: '10%', alignItems: 'center', display: 'flex', flexWrap: 'wrap'}}>
      {menuItems.map((item) => (
        <div key={item.route} style={{ minWidth: '200px', maxWidth: '280px', flexGrow: '1'}}>
          <Button style={{ backgroundColor: '#1b3942', width: '100%', height: '45px' }} onClick={() => navigate(item.route)}>
            <p style={{ color: '#d67729', fontSize: '20px' }} className="text">{item.text}</p>
          </Button>
        </div>
      ))}
    </Container>
  );
}

export default Menu;